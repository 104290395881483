<template>
  <div>
    <van-cell-group title="筛选">
      <van-dropdown-menu>
        <van-dropdown-item v-model="sea.STA" @change="filter" :options="[
            {text:'全部状态', value:0},
            {text:'在用', value:1},
            {text:'库存', value:2},
            {text:'报废', value:3}
        ]" />
      </van-dropdown-menu>
      <van-search v-model="sea.KEY" placeholder="请输入搜索关键字"  @search="filter" @clear="filter"/>
    </van-cell-group>
    <van-divider />
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
      <div class="wcard" v-for="item in lst.da" :key="item.ID">
        <div>
          <van-cell :title="item.NAME" :value="item.POSITION" />
          <van-cell :title="item.PROVIDER" :value="item.CONTACT_MOBILE" />
        </div>
        <div class="ft">
          <van-row>
            <van-col :span="8">
              {{item.STA_TXT}}
            </van-col>
            <van-col :span="8">购买:{{item.PURCHASE_DT|datetime('YYYY-MM-DD')}}</van-col>
            <van-col :span="8" style="text-align: right">保修:{{item.GUARANTEE_EXP|datetime('YYYY-MM-DD')}}</van-col>
          </van-row>
        </div>
      </div>
    </van-list>
  </div>
</template>
<style scoped>
.wcard {margin: 0 12px 12px; overflow: hidden; background-color: #fff; border-radius: 8px; box-shadow: 0 0 4px rgba(0,0,0,.1)}
.wcard .ft {border-top: 1px dashed #ebedf0; padding: 8px 16px; font-size: 12px; min-height: 20px}
</style>
<script>
export default {
  name: "index",
  data() {
    return {
      sea:{
        PAGE_SIZE:10,
        PAGE_INDEX:-1,
        KEY:"",
        STA:0
      },
      lst:{
        da:[],
        loading:false,
        finish:false
      },
    }
  },
  created() {
  },
  methods:{
    filter(){
      this.lst.da.length=0;
      this.lst.finish=false;
      this.sea.PAGE_INDEX=0;
      this.getList();
    },
    loadPage(){
      this.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/CAP/InfoApi/GetList",
        data:this.sea,
        completed(its,n){
          self.lst.da.push(...its.ITEMS);
          self.lst.loading=false;
          if (self.lst.da.length>=n) self.lst.finish=true;
        }
      })
    }
  }
}
</script>

